import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"

const ThanksContact = () => {
  return (
    <Layout>
      <Seo
        pagetitle="問い合わせ完了"
        pagepath="/thanks-contact"
        pagedesc="問い合わせ完了 | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
      />
      <section className="thanks">
        <div className="l-container">
          <h3 className="thanks__title">
            お問い合わせ、ありがとうございます。
          </h3>
          <p className="thanks__subtitle">
            お問い合わせいただいた内容を確認後、3営業日以内にご連絡いたします。
            <br />
            それまでの間、よろしければよくいただくご質問をご覧ください。
          </p>
          <hr className="thanks__hr" />
          <div className="qa-list__box">
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                本当にこの価格で何回もキャンペーンができるのですか？追加費用はかからないのでしょうか。
              </dt>
              <dd className="qa-list__data">
                定額プランでご契約いただいた場合、追加費用不要で何回でもキャンペーンを行っていただけます。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                キャンペーン開始までの流れを教えてください。
              </dt>
              <dd className="qa-list__data">
                お申し込み〜データのご準備・お支払い〜キャンペーン作成〜キャンペーン開始、が基本的な流れです。
              </dd>
              <dd className="qa-list__detail">
                <a href="/qa/starting-a-campaign" className="qa-list__link">
                  詳しく見る
                </a>
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                申し込みの前にデモ画面等で実際の管理画面の操作や参加者の導線を確認することはできますか？
              </dt>
              <dd className="qa-list__data">
                はい、可能です。詳細についてはお問い合わせください。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                解約のための手続きは必要ですか？
              </dt>
              <dd className="qa-list__data">特に必要ありません。</dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                最大で何件までリツイートしたユーザーのデータを収集できますか？
              </dt>
              <dd className="qa-list__data">
                1日10万件以上の収集が可能です。それ以上の件数を収集する必要がある場合は担当者までお問い合わせください。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                キャンペーンに参加したTwtterアカウントの情報はどこまで取得できますか？
              </dt>
              <dd className="qa-list__data">
                Twitterで公開されているプロフィールデータについては基本的に収集できます。個別のアカウントで非公開に設定している情報は取得できません。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                キャンペーンに参加したが抽選に外れてしまったユーザーにアプローチするための仕組みはありますか？
              </dt>
              <dd className="qa-list__data">
                はい、ございます。キャンペーンの設計を工夫してLINEやInstagram、自社サイトなどに誘導することができます。
                <br />
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                認証バッジの取得についてサポートしていただくことはできますか？
              </dt>
              <dd className="qa-list__data">
                はい、できます。詳しくはお問い合わせください。
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThanksContact
